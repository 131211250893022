import axios from "axios"



export const searchListing = (args) => {

    let URL = `${process.env.REACT_APP_API_URL}/Attendance/search-${args.searchFrom}?search=${args.searchString}`
    const token = localStorage.getItem("access_admin_token")

    const headers = {
        "content-Type": "application/json",
        "token": token
    }
    return axios({
        url: URL,
        method: "GET",
        headers: headers,
    })
        .then(response => {
            // console.log("session is ",response)
            return response.data
        })
}

export const getAttendanceChartFromApi = (args) => {

    let URL = `${process.env.REACT_APP_API_URL}/Attendance/monthly-attendance-report?teacher_id=${args.teacher_id}&end_date=${args.end_date}&start_date=${args.start_date}`
    const token = localStorage.getItem("access_admin_token")

    const headers = {
        "content-Type": "application/json",
        "token": token,
    }
    return axios({
        url: URL,
        method: "GET",
        headers: headers,
        
    })
        .then(response => {
            // console.log("session is ",response)
            return response.data
        })
}


export const exportCSV = (payload) => {
  console.log(payload,"payload csv")
    const token = localStorage.getItem("access_admin_token")
    let URL = `${process.env.REACT_APP_API_URL}/Attendance/export-${payload.exportFrom}`
    const headers = {
        "content-type": "application/json",
        "token": token
    }
    return axios({
        url: URL,
        method: "POST",
        headers: headers,
        data: JSON.stringify(payload)
    }).then(response => {
        // console.log("session is ",response)
        return response.data
    })
}



export const filteredListing = (args) => {
     let URL=""

    //  if(args.currentAttendance=="student"){
    //     const limit = (args && args.limit) || 1000
    //     const page =(args.page && args.page) || 1
    //      URL= `${process.env.REACT_APP_API_URL}/Attendance/students-attendance?limit=${limit}&page=${page}`
    //  }
    //  else {
        URL = `${process.env.REACT_APP_API_URL}/Attendance/filter-${args.currentAttendance}-attendance`
     
     
    const token = localStorage.getItem("access_admin_token")
    const headers = {
        "content-Type": "application/json",
        "token": token
    }
    return axios({
        url: URL,
        method: "POST",
        headers: headers,
        data: JSON.stringify(args)
    }).then(response => {
            // console.log("session is ",response)
            return response.data
        })
}
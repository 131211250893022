import * as adminService from '../../services/adminManagement/adminManagement.service'
import * as constants from '../../constants'
import * as commonService from '../../services/commonServices'
import { toast } from "react-toastify"

export const adminListingAction = (payload) => {
    return dispatch => {
        console.log('student listing action')
        return adminService.getAdminListing(payload).then(response => {
            dispatch({ type: constants.SAVE_ADMIN_LISTING, payload: response.data })
        })
    }
}

export const setAddAdminSidebarVisibility = (payload) => {
    return dispatch => {
        dispatch({
            type: constants.SET_ADD_ADMIN_SIDEBAR_VISIBILITY,
            payload
        })
    }
}

export const addNewAdminAction = (payload) => {
    return dispatch => {
        return adminService.addNewAdmin(payload)
    }
}

export const setAddAdminSuccessMessage = (payload) =>{
    return dispatch => {
        dispatch({
            type: constants.SET_ADD_ADMIN_SUCCESS_MESSAGE,
            payload
        })
    }
}

export const blockAdmin = (payload) => {
    return dispatch => {
        return commonService.blockUser(payload)
            .then(response => {
                toast.success('Successfully blocked the student')
    })
     }
 }

 export const deleteAdmin = (payload) =>{
    return dispatch =>{
       return commonService.deleteUser(payload)
            .then(response=>{
                toast.success('Successfully deleted the Admin')
            })
    }
}
export const fecthAdminProfilePage = () =>{

    return dispatch =>{
        adminService.fetchProfilePageData()
         .then(response =>{
            dispatch({
                type: constants.SET_ADMIN_PROFILE_PAGE,
                payload:response
            })
         })

    }
}
export const updateProfilePictureAction = (payload) =>{


    return dispatch =>{

      return  adminService.updateProfilePicture(payload)
        .then(response=>{
            toast.success('Successfully Updated Profile Picture')
        }).catch(error =>{
            toast.error('Something Went wrong')
        })
    }
}

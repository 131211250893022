export const SET_All_TEACHERS_LIST = "SET_All_TEACHERS_LIST"
export const SAVE_TEACHER_SEARCH_LISTING = "SAVE_TEACHER_SEARCH_LISTING"
export const SAVE_TEACHER_SEARCH_STRING = 'SAVE_TEACHER_SEARCH_STRING'
export const SET_TEACHER_LISTING_LIMIT = 'SET_TEACHER_LISTING_LIMIT'
export const SAVE_CLASS_DETAILS = 'SAVE_CLASS_DETAILS'
export const SET_TEACHER_PROFILE_SIDEBAR_VISIBILITY = 'SET_TEACHER_PROFILE_SIDEBAR_VISIBILITY'
export const SET_TEACHER_EDIT_SIDEBAR_VISIBILITY = 'SET_TEACHER_EDIT_SIDEBAR_VISIBILITY'
export const SET_ACTIVE_TEACHER_EDIT = 'SET_ACTIVE_TEACHER_EDIT'

export const SET_ADD_TEACHER_SIDEBAR_VISIBILITY = 'SET_ADD_TEACHER_SIDEBAR_VISIBILITY'
export const SET_ACTIVE_TEACHER_PROFILE = 'SET_ACTIVE_TEACHER_PROFILE'
export const CLEAR_TEACHER_LISTING = 'CLEAR_TEACHER_LISTING'
export const SET_TEACHER_CHECKED_ITEMS = 'SET_TEACHER_CHECKED_ITEMS'
export const SET_TEACHER_FILTER_SIDEBAR_VISIBILITY = 'SET_TEACHER_FILTER_SIDEBAR_VISIBILITY'
export const SET_TEACHER_FILTERS = 'SET_TEACHER_FILTERS'
export const SAVE_FILTERED_TEACHER_LISTING = 'SAVE_FILTERED_TEACHER_LISTING'
export const SET_EDITED_TEACHER_PROFILE = 'SET_EDITED_TEACHER_PROFILE'


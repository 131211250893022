import axios from "axios"

export const getAdminListing = (args) => {
    const limit = (args && args.limit) || 1000
    let URL = `${process.env.REACT_APP_API_URL}/user/show-sub-admin?limit=${limit}`
    const token = localStorage.getItem("access_admin_token")
    const headers = {
        "content-Type": "application/json",
        "token": token
    }
    return axios({
        url: URL,
        method: "GET",
        headers: headers
    }).then(response => {
        // console.log("session is ",response)
        return response.data
    })
}


export const addNewAdmin = (args) => {
    let URL = `${process.env.REACT_APP_API_URL}/user/add-subadmin`
    const token = localStorage.getItem("access_admin_token")
    const headers = {
        "content-Type": "application/json",
        "token": token
    }
    return axios({
        url: URL,
        method: "POST",
        headers: headers,
        data: JSON.stringify(args)
    }).then(response => {
        // console.log("session is ",response)
        return response.data
    })
}
export const fetchProfilePageData = async () => {

    const token = localStorage.getItem("access_admin_token")
    let URL = `${process.env.REACT_APP_API_URL}/user/get-profile`
    const headers ={
        "content-type" : "application/json",
        "token":token
    }
    const response = await axios({
        url: URL,
        method: "GET",
        headers: headers,
        
    });
    console.log("fetched api - " ,response)
    return response.data;
}


export const uploadProfilePictureToS3 = (payload) =>{

    let URL = `${process.env.REACT_APP_API_URL}/upload`

    let formData = new FormData()
    formData.append('file',payload)

    return axios.post(URL,formData)
    .then(response =>{
        return response.data
    })
}

export const updateProfilePicture = (payload) =>{
 
    const token = localStorage.getItem("access_admin_token")
  
    let URL = `${process.env.REACT_APP_API_URL}/user/update-profile-picture`
    const headers ={
        "content-Type" : "application/json",
        "token": token
    }
    return axios({
        url : URL,
        method: "POST",
        headers: headers,
        data : JSON.stringify(payload)
    })
    .then(response =>{
        return response.data
    })
}

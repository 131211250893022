import axios from 'axios';

export const bellNotification = () =>{
   let URL =`${process.env.REACT_APP_API_URL}/userNotification/bell-notifications`
   const token = localStorage.getItem("access_admin_token")
   const headers ={
       "content-Type" : "application/json",
       "token": token
   }
   return axios({
       url:URL,
       method: "GET",
       headers: headers
})
.then(response =>{
   return response.data
})
}


export const markReadBellNotification = (args) =>{
    let URL =`${process.env.REACT_APP_API_URL}/userNotification/read-all-notification`
    const token = localStorage.getItem("access_admin_token")
    const headers ={
        "content-Type" : "application/json",
        "token": token
    }
    return axios({
        url:URL,
        method: "PUT",
        headers: headers,
        data: JSON.stringify(args)
 })
 .then(response =>{
    return response.data
 })
 }
import axios from 'axios';

export const loginInfoFetch = (payload) =>{
    const payloadlogin ={...payload
        // "device_type" :"ADMIN"
    }
    console.log(payloadlogin,"payloadlogin")

    let URL =`${process.env.REACT_APP_API_URL}/user/login`
    console.log(URL,"url")
     const headers ={
         "content-Type" : "application/json",
         "device_type"  : "ADMIN"
     }
    return axios({
        url: URL,
        method: "POST",
        headers: headers,
        data : JSON.stringify(payloadlogin)
    })
     .then(response =>{
         return response.data

     })
 }
 export const validateToken = async (payload) => {
    console.log("my paylad",payload);
    const payloadlogin ={...payload ,
        "device_type":"ADMIN"
        }
    let URL = `${process.env.REACT_APP_API_URL}/user/verify-token`
    const headers ={
        "content-type" : "application/json",
        "device_type"  : "ADMIN"
    }
    return axios({
        url: URL,
        method: "POST",
        headers: headers,
        data: JSON.stringify(payloadlogin)
    }).then(response=>{
        return response.data
    });

  }

  export const verifyOtpToResetPassword = (payload) =>{
    const payloadlogin ={...payload ,
        "device_type":"ADMIN"
        }

    let URL =  `${process.env.REACT_APP_API_URL}/user/verify-otp-to-reset-password`
    const headers ={
        "content-Type" : "application/json",
        "device_type"  : "ADMIN"
    }

    return axios({
        url: URL,
        method: "POST",
        headers: headers,
        data : JSON.stringify(payloadlogin)
    }).then(response=>{
        return response
    })
}

export const reSendOtpToResetPasswordOnMail = (payload) =>{
    let URL =  `${process.env.REACT_APP_API_URL}/user/resend-reset-password-otp`
    const payloadlogin ={...payload ,
        "device_type":"ADMIN"
        }
    const headers ={
        "content-Type" : "application/json",
        "device_type"  : "ADMIN"
    }
    return axios({
        url: URL,
        method: "POST",
        headers: headers,
        data : JSON.stringify(payloadlogin)
    }).then(response=>{
        return response
    })
}
export const resetPassword = (payload) =>{

    let URL = `${process.env.REACT_APP_API_URL}/user/reset-password`
    const payloadlogin ={...payload ,
        "device_type":"ADMIN"
        }
    const headers ={
        "content-Type" : "application/json",
        "device_type"  : "ADMIN"
    }
    return axios({
        url: URL,
        method: "POST",
        headers: headers,
        data : JSON.stringify(payloadlogin)
    })
    .then(response =>{
        return response.data
    })
}
export const forgotPassword = (payload) =>{

    let URL = `${process.env.REACT_APP_API_URL}/user/forget-password`
    console.log(payload,"forfrr")
    const payloadlogin ={...payload,
        "device_type":"ADMIN"
        }
    const headers ={
        "content-Type" : "application/json",
        "device_type"  : "ADMIN"
    }
    return axios({
        url: URL,
        method: "POST",
        headers: headers,
        data : JSON.stringify(payloadlogin)
    })
    .then(response =>{
        return response.data
    })
}

export const SAVE_STUDENT_LISTING = 'SAVE_STUDENT_LISTING'
export const SAVE_STUDENT_SEARCH_LISTING = 'SAVE_STUDENT_SEARCH_LISTING'
export const SAVE_STUDENT_SEARCH_STRING = 'SAVE_STUDENT_SEARCH_STRING'
export const SET_LISTING_LIMIT = 'SET_LISTING_LIMIT'
export const SAVE_CLASS_DETAILS = 'SAVE_CLASS_DETAILS'
export const SET_STUDENT_PROFILE_SIDEBAR_VISIBILITY = 'SET_STUDENT_PROFILE_SIDEBAR_VISIBILITY'
export const SET_ADD_STUDENT_SIDEBAR_VISIBILITY = 'SET_ADD_STUDENT_SIDEBAR_VISIBILITY'
export const SET_ACTIVE_STUDENT_PROFILE = 'SET_ACTIVE_STUDENT_PROFILE'
export const CLEAR_STUDENT_LISTING = 'CLEAR_STUDENT_LISTING'
export const SET_CHECKED_ITEMS = 'SET_CHECKED_ITEMS'
export const SET_STUDENT_FILTER_SIDEBAR_VISIBILITY = 'SET_STUDENT_FILTER_SIDEBAR_VISIBILITY'
export const SET_STUDENT_FILTERS = 'SET_STUDENT_FILTERS'
export const SAVE_FILTERED_STUDENT_LISTING = 'SAVE_FILTERED_STUDENT_LISTING'
export const SET_STUDENT_EDIT_SIDEBAR_VISIBILITY = 'SET_STUDENT_EDIT_SIDEBAR_VISIBILITY'
export const SET_ACTIVE_STUDENT_EDIT = 'SET_ACTIVE_STUDENT_EDIT'
export const SET_EDITED_STUDENT_PROFILE = 'SET_EDITED_STUDENT_PROFILE'


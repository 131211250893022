import { combineReducers } from "redux"
import calenderReducer from "./calendar/"
import customizer from "./customizer/"
import auth from "./auth/"
import dataList from "./data-list/"
import {studentManagement} from './studentManagement/studentManagement.reducer'
import {attendanceManagement} from './attendanceManagement/attendanceManagement.reducer'
import teacherReducer from './teacherManagement/teacherMangement.reducer'
import {classManagement} from '../reducers/classManagement/classManagement.reducer'
import {adminManagement} from '../reducers/adminManagement/adminManagement.reducer'
import {dashboardManagement} from '../reducers/dashboardManagement/dashboardManagement.reducer'
import {notificationReducer} from './notification/notification.reducer'
import {recordedSession} from './recordedSession/recordedSession.reducer'



const appReducer = combineReducers({
  calendar: calenderReducer,
  customizer: customizer,
  auth: auth,
  dataList: dataList,
  studentManagement,
  teacherManagement:teacherReducer,
  classManagement :classManagement,
  adminManagement:  adminManagement,
  dashboardManagement:dashboardManagement,
  notifications: notificationReducer,
  attendanceManagement:attendanceManagement,
  recordedSession,

})


const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if(action.type === "DESTROY_SESSION")
  state = undefined;

  return appReducer(state, action);
};

export default rootReducer

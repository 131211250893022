import * as constants from '../../constants'
import {bellNotification, markReadBellNotification} from '../../services/notification/notification.service'


export const setNotificationAvailableStatus = (payload) => {
    return dispatch => {
            dispatch({ type: constants.SET_NOTIFICATION_AVAILABLE_STATUS, payload: payload })
    }
}

export const bellNotificationAction = (payload) =>{
    return dispatch =>{

         bellNotification()
            .then(response=>{
             dispatch({
                 type:constants.SAVE_BELL_NOTIFICATION,
                 payload:response.data
             })
         }).catch(error =>{
            console.log('err=>', error)

         })

     }
 }

 export const markReadBellNotificationAction = (payload) =>{
    return dispatch =>{

        markReadBellNotification(payload)
            .then(response=>{
             dispatch({
                 type:constants.SAVE_BELL_NOTIFICATION,
                 payload:response.data
             })
         }).catch(error =>{
            console.log('err=>', error)

         })

     }
 }
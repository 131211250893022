import React from "react"
import * as Icon from "react-feather"

const navigationConfigStudent = [

  // {
  //   type: "groupHeader",
  //   groupTitle: "APPS"
  // },
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/dashboard",
    filterBase: "/dashboard"
  },
  {
    id: "teacher_management",
    title: "Teacher Management",
    type: "item",
    icon: <Icon.Book size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/teacher-management"
  },
  {
    id: "student_management",
    title: "Student Management",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/student-management"
  },
  {
    id: "class_management",
    title: "Class Management",
    type: "item",
    icon: <Icon.Monitor size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/class-management"
  },
  {
    id: "attendance_report",
    title: "Attendance Reports",
    type: "item",
    icon: <Icon.Clipboard size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/attendance-reports"
  },
  {
    id: "recorded_session",
    title: "Recorded Sessions",
    type: "item",
    icon: <Icon.Video size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/recorded-sessions"
  },
  // {
  //   id: "note",
  //   title: "Notes",
  //   type: "item",
  //   icon: <Icon.File size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/notes"
  // },
  {
    id: "user_account",
    title: "User Account",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/user-account"
  },
  {
    id: "setup",
    title: "Setup",
    type: "item",
    icon: <Icon.Sliders size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/setup"
  },
]

export default navigationConfigStudent

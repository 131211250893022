import * as constants from "../../constants";

const initialState = {
  activePage: 1,
  searchRecordedSessionListing: [],
  searchString: "",
  limit: 10,
  total: 0,
  classDetails: {},
  checkedItems: new Map(),
  recordedSessionFilterSidebarVisibility: false,
  recordedSessionFilters: {},
  filteredRecordedSessionListing: {},
  recordedSessionShareSidebarVisibility: false,
  recordedSessionStudents: [],
  activeRecordedSession: {},
};

export const recordedSession = (state = initialState, action) => {
  switch (action.type) {
    case constants.SAVE_RECORDED_SESSION_SEARCH_LISTING: {
      const oldListing = action.payload;
      return { ...state, searchRecordedSessionListing: oldListing };
    }
    case constants.SAVE_RECORDED_SESSION_SEARCH_STRING: {
      return {
        ...state,
        searchString: action.payload,
        checkedItems: new Map(),
      };
    }
    case constants.SET_LISTING_LIMIT: {
      return { ...state, limit: action.payload };
    }
    case constants.SAVE_CLASS_DETAILS:
      return {
        ...state,
        classDetails: action.payload,
      };
    case constants.SET_RECORDED_SESSION_FILTER_SIDEBAR_VISIBILITY:
      return {
        ...state,
        recordedSessionFilterSidebarVisibility: action.payload,
      };
    case constants.SET_RECORDED_SESSION_SHARE_SIDEBAR_VISIBILITY:
      return {
        ...state,
        recordedSessionShareSidebarVisibility: action.payload,
      };
    case constants.SET_CHECKED_ITEMS:
      return {
        ...state,
        checkedItems: action.payload,
      };
    case constants.SET_RECORDED_SESSION_FILTERS:
      return {
        ...state,
        recordedSessionFilters: action.payload,
      };
    case constants.CLEAR_RECORDED_SESSION_LISTING:
      return {
        ...state,
        recordedSessionListing: {},
        filteredRecordedSessionListing: {},
        activePage: 1,
        searchRecordedSessionListing: [],
        checkedItems: new Map(),
      };
    case "USER_LOGOUT_SUCCESS":
      return {
        activePage: 1,
        searchRecordedSessionListing: [],
        searchString: "",
        limit: 10,
        total: 0,
        classDetails: {},
        checkedItems: new Map(),
        recordedSessionFilterSidebarVisibility: false,
        recordedSessionFilters: {},
        filteredRecordedSessionListing: {},
        recordedSessionShareSidebarVisibility: false,
        recordedSessionStudents: [],
        activeRecordedSession: {},
      };

    case constants.SAVE_FILTERED_RECORDED_SESSION_LISTING: {
      const oldListing = { ...state.filteredRecordedSessionListing };
      oldListing[action.payload.page] = action.payload;
      const activePage = action.payload.page;
      const total = action.payload.total;
      return {
        ...state,
        filteredRecordedSessionListing: oldListing,
        activePage,
        total,
      };
    }
    case constants.SAVE_RECORDED_SESSION_STUDENTS: {
      return {
        ...state,
        recordedSessionStudents: action.payload.students || [],
        activeRecordedSession: action.payload.recorded_session,
      };
    }
    case constants.SAVE_ACTIVE_RECORDED_SESSION: {
      return { ...state, activeRecordedSession: action.payload };
    }
    default: {
      return state;
    }
  }
};

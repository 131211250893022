export  const LOGIN_WITH_JWT = "LOGIN_WITH_JWT"
export const LOGIN_JWT_FAILED = "LOGIN_JWT_FAILED"
export const UPDATE_TOKEN="UPDATE_TOKEN"
export  const USER_FORGOT_PASSWORD_REQUEST = "USER_FORGOT_PASSWORD_REQUEST"
export const USER_FORGOT_PASSWORD_SUCCESS = "USER_FORGOT_PASSWORD_SUCCESS"
export const USER_FORGOT_PASSWORD_FAIL="USER_FORGOT_PASSWORD_FAIL"
export  const USER_RESET_PASSWORD_REQUEST = "USER_RESET_PASSWORD_REQUEST"
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS"
export const USER_RESET_PASSWORD_FAIL="USER_RESET_PASSWORD_FAIL"


export const SAVE_RECORDED_SESSION_SEARCH_LISTING = 'SAVE_RECORDED_SESSION_SEARCH_LISTING'
export const SAVE_RECORDED_SESSION_SEARCH_STRING = 'SAVE_RECORDED_SESSION_SEARCH_STRING'
export const SET_LISTING_LIMIT = 'SET_LISTING_LIMIT'
export const SAVE_CLASS_DETAILS = 'SAVE_CLASS_DETAILS'
export const SET_RECORDED_SESSION_FILTER_SIDEBAR_VISIBILITY = 'SET_RECORDED_SESSION_FILTER_SIDEBAR_VISIBILITY'
export const SET_RECORDED_SESSION_SHARE_SIDEBAR_VISIBILITY = 'SET_RECORDED_SESSION_SHARE_SIDEBAR_VISIBILITY'
export const SET_CHECKED_ITEMS = 'SET_CHECKED_ITEMS'
export const SET_RECORDED_SESSION_FILTERS = 'SET_RECORDED_SESSION_FILTERS'
export const CLEAR_RECORDED_SESSION_LISTING = 'CLEAR_RECORDED_SESSION_LISTING'
export const SAVE_FILTERED_RECORDED_SESSION_LISTING = 'SAVE_FILTERED_RECORDED_SESSION_LISTING'
export const SAVE_RECORDED_SESSION_STUDENTS = 'SAVE_RECORDED_SESSION_STUDENTS'
export const SAVE_ACTIVE_RECORDED_SESSION = 'SAVE_ACTIVE_RECORDED_SESSION'

import *as actions from '../../constants/loginConstants/loginConstants';
let initialValues = {
  userRole: "admin",
  values :{
    loggedInUser :{
      token:"",
      user:{}
    },
    loggedInWith:""
  }
}
export const login = (state = initialValues, action) => {
  switch (action.type) {
    case "LOGIN_WITH_EMAIL": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_FB": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_TWITTER": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_GOOGLE": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_GITHUB": {
      return { ...state, values: action.payload }
    }
    case actions.LOGIN_WITH_JWT: {
      return{
        ...state,
        values : action.payload
    }
    }
    case actions.LOGIN_JWT_FAILED:{
      return {
        ...state,
        values :{
            loggedInUser:{
              token: null,
              user: null

            },
            loggedInWith:""
        }
    }}
    case actions.UPDATE_TOKEN :
            return {

                ...state,
                values : action.payload
            }
    case "LOGOUT_WITH_JWT": {
      return { ...state, values: action.payload }
    }
    case "LOGOUT_WITH_FIREBASE": {
      return { ...state, values: action.payload }
    }
    case "CHANGE_ROLE": {
      return { ...state, userRole: action.userRole }
    }
    default: {
      return state
    }
  }
}

export const forgotPassword = (state = null, action) => {

  switch (action.type){

      case actions.USER_FORGOT_PASSWORD_SUCCESS :
          return{
              ...state,
              email:action.payload.email,
          }

          default:
          return {
              ...state
          }
  }
}

export const resetPassword = (state = initialValues, action) => {

  switch (action.type){
      case actions.USER_RESET_PASSWORD_REQUEST:
          return {
              ...state,
              requesting: true

          }
      case actions.USER_RESET_PASSWORD_SUCCESS :
          return{
              ...state,
              resetPassword :{
              user:action.payload,
              token: action.payload.token,
              requesting: false
              }
          }
          case actions.USER_RESET_PASSWORD_FAIL:
          return {
              ...state,
              resetPassword :{
                  token: null,
                  user: null,
                  requesting: false
              }
          }
          default:
          return {
              ...state
          }
  }
}

